import React from "react";
import Header from "../component/Common/Header";
import Footer from "../component/Common/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <section
        id="privacy-policy_area"
        className="mt-5 pb-5 About_cont container cms_cont"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="privacy_content">
                <h3 className="pb-7 text-center">REFUND POLICY</h3>
                <div className="privacy_policy_text">
                  <h4>Return and Refund of Products</h4>
                  <p>
                    Product related complaints for refund or replacement are
                    subject to Refund and Return Policy available at Refund
                    Policy. Customers and Vasantham Store unconditionally agree
                    that they are governed by the Return Policy
                  </p>
                  <p>
                    The return process of a Product may be subject to additional
                    terms depending on the nature and category of the Product.
                    Any such additional terms may be specified on the Website or
                    be intimidated by Vasantham Store at the time of purchase of
                    the Product
                  </p>
                </div>
                <div className="privacy_policy_text">
                  <h4>Governing Law and Jurisdiction</h4>
                  <p>
                    By agreeing to these terms and conditions you agree that in
                    case of any dispute you will be governed by the laws of
                    India without reference to conflict of laws principles and
                    exclusive jurisdiction lies with the court of Mumbai
                  </p>
                </div>
                <div className="privacy_policy_text">
                  <h4>Limitation of Liability</h4>
                  <p>
                    Vasantham Store shall not be liable for any special,
                    incidental, direct or consequential damages in connection to
                    terms of use, even if the concerned user has informed in
                    advance about the possibility of damages.
                  </p>
                </div>

                <div className="privacy_policy_text">
                  <h4>Customer Access</h4>
                  <p>
                    Vasantham Store may deny customers access to the site at any
                    time and may do so immediately without notice. Such denial
                    of access will be without any liability to the Vasantham
                    Store.
                  </p>
                </div>

                <div className="privacy_policy_text">
                  <h4>TERMS AND CONDITIONS OF SALE</h4>
                  <p>Welcome to Vasantham Store!</p>
                  <p>
                    The terms and conditions of sale as set out herein (“ Terms
                    of Sale”) constitute an agreement between the person/ entity
                    listed as seller (“we”, “us” or “our”) on the portal
                    vasanthamstore.com and its corresponding mobile application
                    (collectively, Vasantham Store) and you (“ you” or “your”)
                    and govern your purchase of the products (“Product(s)”) from
                    us on or through Vasantham Store. By placing an order for
                    any Product with us or making a purchase of Product, you
                    expressly agree to be bound by these Terms of Sale.
                  </p>
                  <p>
                    These Terms of Sale are in addition to various agreements,
                    Service Terms, Privacy Policy, Terms of Use and all other
                    policies of Vasantham Store. These Terms of Sale constitute
                    an electronic record within the meaning of the applicable
                    law. This electronic record is generated by a computer
                    system and does not require any physical or digital
                    signatures.
                  </p>
                  <ul>
                    <li>
                      Our Contract of SaleListing and display of a Product by us
                      on Vasantham Store is our invitation to you to make an
                      offer for purchase of such Product. Likewise, the
                      placement of an order on Vasantham Store by you is your
                      offer to buy the Product(s) from us.
                    </li>
                    <li>
                      Once you have placed an order with us for purchase of a
                      Product, you will receive an email confirming receipt of
                      your order and containing the details of your order (the
                      “Order Confirmation”). The Order Confirmation is merely an
                      acknowledgement that we have received your order and does
                      not signify our acceptance of your offer.
                    </li>
                    <li>
                      We only accept your offer and conclude the contract of
                      sale for a Product ordered by you, when the Product is
                      shipped or dispatched to you and an e-mail confirmation is
                      sent to you that the Product has been shipped or
                      dispatched to you (the “Shipment Confirmation”). We
                      reserve the right, at our sole discretion, to refuse or
                      cancel any order for any reason whatsoever before sending
                      Shipment Confirmation to you.
                    </li>
                    <li>
                      You acknowledge that there may be certain Products in your
                      order whose actual quantity may not be known at the time
                      of order placement (for example,Grocery,Kids Items, etc.).
                      Such Products will therefore be invoiced based on the
                      exact weight at the time of packaging and upon dispatch,
                      our Shipment Confirmation will reflect such marginal
                      variance in the quantity of the Product as against your
                      original order and relevant adjustment in the invoice
                      amount.
                    </li>
                    <li>
                      If your order is dispatched in more than one package, you
                      may receive separate Shipment Confirmations for each
                      package, and each Shipment Confirmation and corresponding
                      dispatch will conclude a separate contract of sale between
                      you and us for the Product(s) specified in that Shipment
                      Confirmation.
                    </li>
                    <li>
                      Your contract is with us and you confirm that the
                      Product(s) ordered by you are purchased for your internal
                      / personal purpose and not for resale or business purpose.
                      Please note that we sell Products only in quantities which
                      correspond to the usual requirements of an average
                      household. This applies both to the number of Products
                      ordered within a single order and the placing of several
                      orders for the same Product where the individual orders
                      comprise a quantity typical for a normal household.
                    </li>
                    <li>
                      You can cancel your order for a Product at no cost at any
                      time before we send the Shipment Confirmation relating to
                      that Product
                    </li>
                    <li>
                      All orders placed on Vasantham Store are subject to the
                      availability of such Product as Minimum and maximum
                      delivery timeline , our acceptance of your offer as per
                      the above and your continued adherence to these Terms of
                      Sale
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
