export const GET_SLIDER_LIST = "GET_SLIDER_LIST";
export const GET_HOMEPRODUCT_LIST="GET_HOMEPRODUCT_LIST"
export const WISHLIST_FAVORITES="WISHLIST_FAVORITES"
export const GET_CATEGORY_LISTITEM="GET_CATEGORY_LISTITEM"
export const GET_PROFILE_DATA="GET_PROFILE_DATA"
export const USER_PROFILE_UPDATE="USER_PROFILE_UPDATE"
export const GET_WISH_LIST="GET_WISH_LIST"
export const DELETE_WISHLIST="DELETE_WISHLIST"
export const ADD_WISHLIST="ADD_WISHLIST"
export const GET_SINGLEPRODUCT_LIST="GET_SINGLEPRODUCT_LIST"
export const GET_SHIPPING_LIST="GET_SHIPPING_LIST"
export const GET_COUPONCODE="GET_COUPONCODE"
export const ORDER_CREATE="ORDER_CREATE"
export const USER_ORDERS="USER_ORDERS"
export const SEARCH_CATEGORY="SEARCH_CATEGORY"
export const GET_ADDRESS_LIST="GET_ADDRESS_LIST"
export const PINCODE_CHECK="PINCODE_CHECK"
export const ORDER_LIST="ORDER_LIST"
export const RELATED_LIST="RELATED_LIST"
export const TOTALCART="TOTALCART"
export const ADD_ADDRESS="ADD_ADDRESS"
export const ADVERTISEMENT_DETAIL="ADVERTISEMENT_DETAIL"
export const REWARD_POINTS="REWARD_POINTS"
// login
export const USERLOGIN="USERLOGIN"
export const USER_REGISTER="USER_REGISTER"
export const ENQUIRY_REGISTER="ENQUIRY_REGISTER"
export const FORGOT_PASSWORD="FORGOT_PASSWORD"
export const GET_PACKAGE="GET_PACKAGE" 
export const GET_CITY_LIST="GET_CITY_LIST"
export const DELETE_ADDRESS="DELETE_ADDRESS"
export const EDIT_ADDRESS="EDIT_ADDRESS"



// former
export const GET_POST_LIST="GET_POST_LIST"
export const CREATE_POST="CREATE_POST"
export const FARMER_POST_LIST="FARMER_POST_LIST"
export const STOCK_LIST="STOCK_LIST"
export const DELETE_POSTS="DELETE_POSTS"
export const UPDATE_POSTS="UPDATE_POSTS"
export const FAQ_LIST="FAQ_LIST"
export const ADDITIONAL_CHARGES="ADDITIONAL_CHARGES"
export const RAZORPAY_DETAILS="RAZORPAY_DETAILS"
export const ADDTOCART="ADDTOCART"
export const POST_LIKE="POST_LIKE"
export const ADVISOR_VISIT="ADVISOR_VISIT"

export const BILLING_INFORMATION="BILLING_INFORMATION"
export const COUPONCODE="COUPONCODE"

export const CATEGORY_LIST="CATEGORY_LIST"
export const CART_TOTAL="CART_TOTAL"
export const REDEEM__HISTORY_POINTS="REDEEM__HISTORY_POINTS"
export const CONTACT_US="CONTACT_US"
export const MYBILLS="MYBILLS"
export const REDEEM_POINT_HISTORY="REDEEM_POINT_HISTORY"
export const NOTIFICATIONS="NOTIFICATIONS"
export const GET_HOTPRODUCT_LIST="GET_HOTPRODUCT_LIST"



