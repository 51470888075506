import React from "react";
import Heading from "../../Fashion/Heading";
import ProductCards from "../Product/ProductCard";
const RelatedProducts = ({ RelatedProduct }) => {
  return (
    <div>
      {RelatedProduct.length > 0 && (
        <section id="product_variation_one" className="pt-2 sort_list">
          <div className="container-fluid">
            <Heading heading="Related Products" />
            <div className="row">
              {RelatedProduct &&
                RelatedProduct?.slice(0, 6)?.map((data, index) => {
                  return (
                    <div
                      className="col-lg-2 col-md-4 col-sm-6 col-6"
                      key={index}
                    >
                      <ProductCards data={data} key={index} />
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default RelatedProducts;
