import React from 'react'
import InvoiceOnes from '../../component/InvoiceOne';
const InvoiceOne = () => {
    return (
        <>
            <InvoiceOnes />
        </>
    )
}

export default InvoiceOne;