import React, { useState, useEffect } from "react";
import placeholder from "../assets/no-image-found.png";
export default function CustomImage({ src, alt, className, key, style }) {
  const [imageFile, setState] = useState(null);
  useEffect(() => {
    setState(src);
  }, [src]);

  return (
    <img
      src={imageFile}
      alt={alt}
      style={style}
      key={key}
      className={className}
      onError={() => {
        setState(placeholder);
      }}
      loading="lazy"
    />
  );
}
