import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImageUrl } from "../../../Redux/Utils/baseurl";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import { isMobile } from "react-device-detect";
import Carosal from "../../Carosal";
import { Get_Slider_List } from "../../../Redux/Action/allActions";
import { useDispatch, useSelector } from "react-redux";
const Banner = (props) => {
  const dispatch = useDispatch();
  const Sliderlist = useSelector((state) => state.AllReducer.Slider_list);
  const [settings, setsettings] = useState({
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    fade: true,
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  });

  return (
    <section
      id="furniture_banner"
      className="banner_slide container p-0"
      style={{ width: "100%" }}
    >
      {Sliderlist.length > 0 ? (
        <div>
          <Carosal
            customsettings={settings}
            customcss="banner-slider"
            autoplay={true}
            content={Sliderlist.map((data, index) => {
              return (
                <img
                  key={index}
                  src={`${ImageUrl}${isMobile ? data?.mobile : data?.photo}`}
                  style={{
                    width: "100%",
                  }}
                />
              );
            })}
          />
        </div>
      ) : (
        <Skeleton
          variant="rectangular"
          width={"100%"}
          height={isMobile ? 200 : 320}
        />
      )}
    </section>
  );
};

export default Banner;
