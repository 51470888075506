import React from 'react'
import Header from '../component/Common/Header'
import MobileNumberVerification from '../component/Register/MobileVerification'
import Footer from '../component/Common/Footer'
const Verification = () => {
    return (
        <>
            <Header />
            <MobileNumberVerification />
            <Footer/>
        </>
    )
}

export default Verification;