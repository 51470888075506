import React from "react";
import Header from "../component/Common/Header";
import Footer from "../component/Common/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <>
        <section
          id="privacy-policy_area"
          className="mt-5 pb-5 About_cont container cms_cont"
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="privacy_content">
                  <h3 className="pb-5 text-center">TERMS & CONDITIONS</h3>
                  <p>
                    The Terms and Conditions (T&C) described below constitute an
                    electronic contract, enforceable as per provisions of the
                    Information Technology Act, 2000 and Rules made thereunder,
                    as amended from time to time. Please note that the T & C
                    will not require any signature for acceptance and
                    enforceability. We encourage our esteemed customers to read
                    these terms fully before purchasing any products from our
                    website or mobile apps.
                  </p>
                  <div className="privacy_policy_text">
                    <h4>Offer and Acceptance</h4>
                    <p>
                      The Customer agrees to these T&C by accessing this
                      Website. All commercial/contractual terms are offered by
                      and agreed to between Customers and Vasantham Store alone.
                    </p>
                    <p>
                      Vasantham Store may include additional or conflicting
                      terms and conditions of sale in product listing or product
                      description as made available on the website. If there is
                      any conflict between the T&C and the additional terms of
                      sale, the additional terms of sale shall take precedence
                      to the extent of such conflict and in relation to that
                      sale.
                    </p>
                    <p>
                      Vasantham Store may at any time modify the Terms and
                      Conditions for use of the Website without any prior
                      notification to the customers. If a customer does not
                      agree with the T&C he/she is advised not to buy or attempt
                      to buy any products listed on the Website. However, if the
                      customer continues to use the service he/she shall be
                      deemed to have agreed to accept and abide by the modified
                      Terms & Conditions of Use of this Website.
                    </p>
                  </div>
                  <div className="privacy_policy_text">
                    <h4>Limitations</h4>
                    <p>
                      Services associated with the Website would be available to
                      only select geographies in India as solely determined by
                      Vasantham Store
                    </p>
                    <p>
                      Persons who are "incompetent to contract" within the
                      meaning of the Indian Contract Act, 1872 as detailed in
                      the Privacy Policy are not eligible to use the Website.
                      Vasantham Store shall not be held directly or indirectly
                      liable for any damages arising from the customer’s or any
                      3rd party’s misuse or violation of the Terms & Conditions
                      of the Vasantham Store site and mobile apps, and any other
                      related policies associated with the Website.
                    </p>
                    <p>
                      Vasantham Store does not implicitly or explicitly endorse
                      or advertise the products and associated brands on the
                      Website. References on this Website to any names, marks,
                      products or services of third parties or hypertext links
                      to third party sites or information are provided solely as
                      a convenience to the Customer and do not in any way
                      constitute or imply to be Vasantham Store endorsement,
                      sponsorship or recommendation of the third party,
                      information, products or service
                    </p>
                  </div>
                  <div className="privacy_policy_text">
                    <h4>Intellectual Property Rights (IPR)</h4>
                    <p>
                      Vasantham Store expressly reserves all intellectual
                      property rights in all text, programs,products, processes,
                      technology, content and other materials, which appear on
                      this Website and mobile apps. Access to this Website does
                      not confer and shall not be considered as conferring upon
                      anyone any license under any of Vasantham Store or any
                      third party's intellectual property rights.
                    </p>
                    <p>
                      Apart from the brands / Intellectual Property Rights (IPR)
                      owned by Vasantham Store, all other trademarks/ brands
                      displayed on the Website are the property of their
                      respective brand owners and displayed on the Website with
                      prior consent by Vasantham Store.
                    </p>
                    <p>
                      Vasantham Store hereby allows the Customer to only access
                      the website for personal use and not to download or modify
                      any portion of it. This website or any portion of this
                      Website may not be reproduced, duplicated, copied, sold,
                      resold, visited, or otherwise exploited for any commercial
                      purpose without the express written consent of Vasantham
                      Store.
                    </p>
                    <p>
                      All reviews, comments, feedback, postcards, suggestions,
                      ideas, and other submissions disclosed, submitted or
                      offered to the Website on or by this Site or otherwise
                      disclosed, submitted or offered in connection with the use
                      of this Website shall be and remain the property of
                      Vasantham Store. Such disclosure, submission or offer of
                      any Feedback on the Website shall constitute an assignment
                      t Vasantham Store of all worldwide rights, titles and
                      interests in all copyrights and other intellectual
                      properties in the Feedback by the Users.
                    </p>
                  </div>
                  <div className="privacy_policy_text">
                    <h4>Representation</h4>
                    <p>
                      Vasantham Store does not make any Representation as to
                      produce being products proposed to be sold or offered to
                      be sold or purchased on the Website
                    </p>
                    <p>
                      All offers of sale of products are governed by the
                      description and specifications of the Brand.
                    </p>
                  </div>
                  <div className="privacy_policy_text">
                    <h4>Warranty</h4>
                    <p>
                      With respect to the sale of Product of Vasantham Store to
                      the Customer Vasantham Store hereby represents and
                      warrants to the Customer that
                    </p>
                    <ul>
                      <li>
                        Vasantham Store has the right to sell the Products to
                        the Customer through the Website.
                      </li>
                      <li>
                        Customer shall have and enjoy quiet possession of the
                        Products on completion of sale
                      </li>
                      <li>
                        Products shall be free from any charge or encumbrance in
                        favour of third party
                      </li>
                      <li>
                        Customer shall be entitled to all the warranties and
                        other collaterals applicable to the Product or as
                        generally made available by third party vendor of the
                        Product
                      </li>
                      <li>
                        Vasantham Store has made every effort to display the
                        Products that appear on the Website as accurately as
                        possible. However, any deviance in appearance in the
                        actual product cannot be held against the Vasantham
                        Store.
                      </li>
                    </ul>
                    <p>
                      The Customer hereby represents and warrants to Vasantham
                      Store
                    </p>
                    <ul>
                      <li>
                        That the Customer is accessing the Website and
                        transacting at his/her sole risk and is using his/her
                        best informed and prudent judgement before entering into
                        any transaction through this Website.
                      </li>
                      <li>
                        Customers will use the Website for lawful purposes only
                        and comply with all applicable laws and regulations
                        while using and transacting on the Website.
                      </li>
                      <li>
                        Customer will provide authentic and true information in
                        all instances where such information is requested by
                        Vasantham Store reserves the right to confirm and
                        validate the information and other details provided by
                        the customer at any point of time.
                      </li>
                      <li>
                        If upon confirmation the Customer’s details are found
                        not to be true (wholly or partly) Vasantham Store has
                        the right in its sole discretion to reject the
                        registration and debar such customer from accessing the
                        Website without prior intimation whatsoever.
                      </li>
                      <li>
                        The address at which delivery of the product ordered by
                        the Customer is to be made will be correct and proper in
                        all respects. Customer is completely responsible and
                        liable for the correctness of delivery address details
                        in this regard.
                      </li>
                      <li>
                        Before placing an order, customers will check the
                        product description carefully. By placing an order for a
                        product he/she agrees to be bound by the conditions of
                        sale included in the item's description.
                      </li>
                    </ul>
                  </div>
                  <div className="privacy_policy_text">
                    <h4>Pricing Information</h4>
                    <p>
                      All the products listed on the Website will be sold at MRP
                      unless otherwise specified. The prices mentioned at the
                      time of ordering will be the prices charged on the date of
                      the delivery. All prices are inclusive of GST unless
                      stated otherwise
                    </p>
                  </div>
                  <div className="privacy_policy_text">
                    <h4>Delivery of the Product</h4>
                    <p>
                      Vasantham Store currently offers two modes of delivery for
                      purchases made on Vasantham Store.
                    </p>
                  </div>
                  <div className="privacy_policy_text">
                    <h4>Home Delivery</h4>
                    <p>
                      Please note that for all home deliveries Vasantham Store
                      will charge a delivery fee. This delivery fee will be
                      added to the billed amount at the time of checkout, while
                      placing the order online.
                    </p>
                  </div>
                  <div className="privacy_policy_text">
                    <h4>Tentative Time for Delivery</h4>
                    <p>
                      The typical time that is required to process an order is
                      24 hours from receiving the order online. Please note all
                      items (including gifts) will be shipped with an invoice
                      mentioning the price. There is no guaranteed dispatch time
                      and any information about the dispatch time is estimated
                      only and should not be relied upon as such. Therefore,
                      time is not the essence of the bi-partite contract between
                      the Customer and Vasantham Store for purchase and sale of
                      Product through the Website.
                    </p>
                    <p>
                      If you believe that the products you ordered are not in
                      good condition, or if the packaging is tampered with or
                      damaged, please refuse to take delivery of the package. In
                      case you find an issue with the product after accepting
                      delivery, you may return the said product and seek
                      recourse as per the terms of refund policy.
                    </p>
                    <p>
                      Vasantham Store will make its best efforts to service all
                      customer orders accurately and on time. However, if in
                      certain pressing situations (including force majeure), we
                      are unable to fulfil an order we will not recompense the
                      customer for the delay or the non-delivery of the orders.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
