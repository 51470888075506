import React, { useState } from "react";
import payment from "../../../assets/img/payment.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { apiurl } from "../../../Redux/Utils/baseurl";
import logo from "../../../assets/img/logo.gif";
import whatsapp from "../../../assets/img/whatsapp.png";
import { FacebookShareButton, TwitterShareButton } from "react-share";
const FooterData = [
  {
    title: "Company",
    links: [
      { linkTitle: "Terms and Condition", link: "/terms" },
      { linkTitle: "Privacy Policy", link: "/privacy-policy" },
      { linkTitle: "Refund Policy", link: "/refund" },
      { linkTitle: "FAQ", link: "/faqs" },
      { linkTitle: "About", link: "https://vasanthamstore.com/about/" },
    ],
  },
  {
    title: "Our branches",
    links: [
      { linkTitle: "Kalavasal - Madurai" },
      { linkTitle: "Bypass Road - Madurai" },
      { linkTitle: "Virattipathu - Madurai" },
      { linkTitle: "K.Pudur - Madurai" },
      { linkTitle: "Koodal Nagar - Madurai" },
      { linkTitle: "Vilangudi - Madurai" },
    ],
  },
];

const Footer = ({ hide }) => {
  const [email, setemail] = useState();
  const [showScroll, setShowScroll] = useState(false);
  const [loading, setloading] = useState(false);

  const SubscribeNews = () => {
    setloading(true);
    axios({
      method: "post",
      url: apiurl + "subscribeSubmit",
      data: {
        email: email,
      },
    }).then((res) => {
      setloading(false);
      if (res.data.msg) {
        setemail("");
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: res.data.msg,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Failed!",
          text: Object.values(res?.data?.errors).join("\n"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  window.addEventListener("scroll", checkScrollTop);
  return (
    <>
      <div className="footer_hide">
        {/* <Appfooter/> */}
        <footer id="footer_one">
          <div className="container">
            <div className="row">
              <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                <div className="footer_left_side">
                  <Link to="/">
                    <img src={logo} alt="logo" className="footerImage" />
                  </Link>
                  <div className="footer_left_side_icon">
                    <ul>
                      <li>
                        <FacebookShareButton
                          url={"https://www.facebook.com/Vasanthamsupermart"}
                        >
                          <a>
                            <i className="fa fa-facebook-f"></i>
                          </a>
                        </FacebookShareButton>
                      </li>
                      <li>
                        <TwitterShareButton
                          url={"https://twitter.com/Vasanthamstore"}
                        >
                          <a>
                            <i className="fa fa-twitter"></i>
                          </a>
                        </TwitterShareButton>
                      </li>

                      <li>
                        <a>
                          <i
                            class="fa fa-instagram"
                            aria-hidden="true"
                            onClick={() =>
                              window.open(
                                "https://www.instagram.com/vasanthamsupermart"
                              )
                            }
                          ></i>
                        </a>
                      </li>

                      <li>
                        <a>
                          <i
                            class="fa fa-youtube-play"
                            onClick={() =>
                              window.open(
                                "https://www.youtube.com/channel/UCYIia86MKba5BBiy0YX7Yqw"
                              )
                            }
                            aria-hidden="true"
                          ></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                {FooterData?.slice(0, 1).map((data, index) => (
                  <div className="footer_one_widget" key={index}>
                    <h3>{data.title}</h3>
                    <ul>
                      {data.links.map((link, index) =>
                        link.linkTitle === "About" ? (
                          <li key={index}>
                            <a onClick={() => window.open(link.link, "_blank")}>
                              {link.linkTitle}
                            </a>
                          </li>
                        ) : (
                          <li key={index}>
                            <a to={link.link}>{link.linkTitle}</a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                ))}
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                {FooterData?.slice(1, 2).map((data, index) => (
                  <div className="footer_one_widget" key={index}>
                    <h3>{data.title}</h3>
                    <ul>
                      {data.links.map((link, index) => (
                        <li key={index} className="link_tag">
                          {link.linkTitle}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                <div className="footer_one_widget">
                  <h3>News letter</h3>
                  <div id="mc_embed_signup" className="subscribe-form">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        !loading && SubscribeNews();
                      }}
                    >
                      <div className="mc-form">
                        <input
                          className="form-control"
                          type="email"
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                          placeholder="Your Mail"
                          name="EMAIL"
                          defaultValue=""
                          required
                          pattern=".+@gmail\.com"
                        />
                        <div className="clear">
                          <button
                            className="theme-btn-one btn_md"
                            type="submit"
                            name="subscribe"
                            defaultValue=""
                          >
                            {loading && (
                              <i
                                className="fa fa-spinner fa-spin "
                                style={{ fontSize: "14px", marginTop: "4px" }}
                              />
                            )}{" "}
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showScroll && (
            <div
              className="go-top active"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <i className="fa fa-chevron-up"></i>
              <i className="fa fa-arrow-up"></i>
            </div>
          )}
          <div className="whatsic">
            <img
              src={whatsapp}
              onClick={() =>
                window.open("https://wa.me/916381594409", "_blank")
              }
            />
          </div>
        </footer>

        <section id="copyright_one">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="copyright_left">
                  <h6>
                    © Copyright 2023 <span>Vasantham</span>
                  </h6>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="copyright_right">
                  <img src={payment} alt="img" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Footer;
