import React from "react";
import Header from "../../component/Common/Header";
import EmptyCart from "../../component/Cart/EmptyCart";
import Footer from "../../component/Common/Footer";
const EmptyCarts = () => {
  return (
    <>
      <Header />
      <EmptyCart />
      <Footer />
    </>
  );
};

export default EmptyCarts;
