import React from 'react'
import OrderSuccess from '../../component/OrderSuccess'
const OrderSuccesses = () => {
    return (
        <>
            <OrderSuccess />
        </>
    )
}

export default OrderSuccesses