import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholder from "../assets/no-image-found.png";
const LazyLoadImg = ({ image }) => (
  <div>
    <LazyLoadImage
      key={image}
      src={image}
      effect="blur"
      alt={image}
      placeholderSrc={placeholder}
    />
  </div>
);

export default LazyLoadImg;
