import React from "react";
import Header from "../../component/Common/Header";
import CartArea from "../../component/Cart";
import Footer from "../../component/Common/Footer";

const Cart = () => {
  return (
    <>
      <Header />
      <CartArea />
      <Footer />
    </>
  );
};

export default Cart;
